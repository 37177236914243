import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const BookingTransferPopup = lazy(() => import("./BookingTransferPopup"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  CONFIRMATION_FORM: <ConfirmationForm />,
  BOOKING_TRANSFER: <BookingTransferPopup />,
};
