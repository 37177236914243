import { lazy } from "react";


const Login = lazy(() => import("pages/Login"));
const Bookings = lazy(() => import("pages/Booking"));

export { default } from "./AppRoutes";

export interface IRoute {
	path: string;
	element: JSX.Element;
}

export const public_routes: IRoute[] = [{ path: "/", element: <Login /> }];

export const private_routes: IRoute[] = [


	{ path: "/bookings", element: <Bookings /> },

];
