import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BookingState,
  SetBookingsPayload,
  BookingLoadingPayload,
  UpdateBookingPayload,
} from ".";

const initialBooking = {
  count: 0,
  bookings: [],
  loading: true,
};
const initialState: BookingState = {
  tab: 0,
  refresh: 0,
  booking: null,
  loading: false,
  refreshLoader: false,
  available: initialBooking,
  picked: initialBooking,
  completed: initialBooking,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<BookingLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setBookings: (state, action: PayloadAction<SetBookingsPayload>) => {
      const { type, count, bookings } = action.payload;

      state.refreshLoader = false;

      state[type].count = count;
      state[type].bookings = bookings;
    },
    pickBooking: (state, action: PayloadAction<UpdateBookingPayload>) => {
      const { id, type, booking } = action.payload;
      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state["picked"].count += 1;
          state[type].bookings.splice(i, 1);
          state["picked"].bookings.unshift(booking);
          return false;
        }
        return true;
      });
    },
    completeBooking: (state, action: PayloadAction<UpdateBookingPayload>) => {
      const { id, type, booking } = action.payload;

      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state["completed"].count += 1;
          state[type].bookings.splice(i, 1);
          state["completed"].bookings.unshift(booking);
          return false;
        }
        return true;
      });
    },
  },
});

const bookingReducer = bookingSlice.reducer;

export const bookingActions = bookingSlice.actions;
export default bookingReducer;
