export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  BOOKING_TRANSFER = "BOOKING_TRANSFER",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
